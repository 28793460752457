@import "../bootstrap.css";
@import "../base.css";
@import "../standard.css";

/* Carousel */
.my-carousel {
  width: 100%;
  margin: 0px 0px 10px 0px;
}

@media only screen and (min-width: 1050px) {
  .my-carousel {
    width: 100%;
    margin: 20px 0px 20px 0px;
  }

  .carousel-inner {
    border-radius: 10px;
  }
}

.carousel-indicators {
  width: 100%;
  margin: 0 auto;
}

/* Header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  margin: 0 auto;
  border-bottom: 1px solid #6585ac69;
  z-index: 2;
}

.header-main {
  width: 100%;
}

.header-main a img {
  margin-left: 20px;
  height: 40px;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.header-nav {
  list-style: none;
  margin: 0;
  padding: 0px 20px 0px 0px;
}

.header-nav li {
  text-align: right;
}

.header-nav li a {
  font-size: 14px;
  padding: 0px 20px 0px 10px;
  color: #202020;
  text-decoration: none;
  text-align: right;
}

.header-nav li a:hover {
  font-weight: 500;
  text-shadow: 1px 1px 4px #f3f3f3;
}

@media only screen and (min-width: 1050px) {
  .header-main {
    width: 1000px;
  }

  .header-main a img {
    height: 40px;
  }

  .header-nav li a {
    padding: 0px 0px 0px 10px;
  }
}

/* Outlet */
.content {
  margin: 0 auto;
  margin-top: 80px;
  width: 100%;
}

@media only screen and (min-width: 1050px) {
  .content {
    width: 1000px;
  }
}

/* Button */
.my-button {
  border-radius: 10px;
  border: none;
  font-family: "Open Sans", sans-serif;
}

.button-primary {
  background-color: #377dd3;
  border: none;
  color: #fff;
  transition: 0.2s;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.074);
}

.button-disabled {
  opacity: 0.4;
}

.button-disabled:hover {
  cursor: not-allowed;
}

.button-primary:hover {
  background-color: #2764af;
}

.button-secondary {
  background-color: #fff;
  border: none;
  color: #202020;
  transition: 0.2s;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.074);
  border: 1px solid #20202040;
}

.button-secondary:hover {
  background-color: #fafafa;
}

.button-image {
  padding: 10px;
}

.button-big {
  line-height: 50px;
  font-weight: 600;
  padding: 0px 30px;
  font-size: large;
}

.button-medium {
  line-height: 40px;
  font-weight: 600;
  padding: 0px 25px;
  font-size: medium;
}

.button-small {
  line-height: 35px;
  font-weight: 500;
  padding: 0px 20px;
  font-size: small;
}

/* Footer */
.footer {
  background-color: #eee;
  position: relative;
  bottom: 0;
  left: 0;
  margin-top: 50px;
}

.footer p {
  color: #777;
}

.footer p a {
  color: #777;
  text-decoration: none;
}

.footer div {
  width: 100%;
}

@media only screen and (min-width: 1050px) {
  .footer div {
    width: 1000px;
  }
}

.header-p {
  padding: 0px 0px 0px 8px;
  font-size: 14px;
  font-weight: 400;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.header-steps li {
  margin-left: 5px;
  padding: 10px;
  transition: 0.7s;
}

.header-steps li:hover {
  box-shadow: 1px 1px 4px #f8f8f8;
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  text-shadow: 1px 1px 4px #f8f8f8;
}

.header-p-selected {
  padding: 0px 0px 0px 8px;
  font-size: 14px;
  color: var(--blue-color);
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .header-p {
    display: none;
  }
  .header-p-selected {
    display: none;
  }
  .header-steps {
    padding-right: 20px;
  }
  .header-steps li:hover {
    background-color: #fff;
  }
}

/* MyRadioInput */

.my-radio-input {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #fff;
  border: 2px solid var(--blue-color);
}

.my-radio-input-selected {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  background-color: #fff;
  border: 11px solid var(--blue-color);
}

/* NextStepReservation */

.reservation-step-no-display {
  position: fixed;
  bottom: -500px !important;
  opacity: 0;
  background-color: #717171;
}

.reservation-step {
  position: fixed;
  bottom: 0;
  border-top: 1px solid #71717128;
  border-left: 1px solid #71717128;
  border-right: 1px solid #71717128;
  border-radius: 5px;
  padding: 0px 20px;
  height: 100px;
  width: 90%;
  transition: 300ms;
  opacity: 1;
  z-index: 2;
  background-color: #fff;
}

.reservation-step h3 {
  font-weight: 400;
  line-height: 25px;
}

.reservation-step h4 {
  font-weight: 400;
  line-height: 25px;
}

.reservation-step h5 {
  font-weight: 400;
  line-height: 25px;
}

.reservation-step h6 {
  font-weight: 400;
  line-height: 25px;
}

@media only screen and (min-width: 1050px) {
  .reservation-step {
    margin: 0px 0px 25px 0px;
    border-bottom: 2px solid #71717128;
    height: 120px;
    width: 800px;
  }

  .reservation-step h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 35px;
  }

  .reservation-step h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 35px;
    padding-left: 20px;
  }

  .reservation-step h5 {
    display: block;
    line-height: 20px;
  }

  .reservation-step h6 {
    display: block;
    line-height: 20px;
  }
}

.date-div-selected {
  border: 1px solid #71717128;
  background-color: #71717110;
}

.date-div {
  padding: 8px;
  border: 1px solid #71717128;
  margin: 0px 5px;
  border-radius: 10px;
}

.date-div:hover {
  cursor: pointer;
}

.date-div h4 {
  line-height: 20px;
  font-size: 16px;
  user-select: none;
}

.date-div h5 {
  line-height: 20px;
  font-size: 14px;
  width: 55px;
  user-select: none;
}

.date-div p {
  line-height: 30px;
  user-select: none;
}

.date-div h3 {
  line-height: 20px;
  font-size: 16px;
  user-select: none;
}

.left-button-date {
  width: 22px;
  height: 25px;
  transform: rotate(180deg);
  opacity: 0.8;
}

.right-button-date {
  width: 22px;
  height: 25px;
  opacity: 0.8;
}

.left-button-date:hover,
.right-button-date:hover {
  cursor: pointer;
}

.my-input {
  width: min(800px, 100%);
  border-radius: 5px;
  line-height: 24px;
  font-size: 15px;
  border: 1px solid #aaa;
  margin: 10px 0px 10px 0px;
  padding: 3px 7px;
  color: #404040;
  font-family: "Open Sans", sans-serif;
}

.my-input::placeholder {
  color: #aaa;
}

.my-input:focus {
  outline: none;
  border: 1px solid #909090;
}

.my-input-error {
  border: 1px solid #f64e4ed4;
}

.my-input-error:focus {
  border: 1px solid #f64e4e55;
}

.my-text-area {
  width: min(800px, 100%);
  border-radius: 5px;
  line-height: 24px;
  font-size: 15px;
  border: 1px solid #aaa;
  margin: 10px 0px 10px 0px;
  padding: 3px 7px;
  color: #404040;
  font-family: "Open Sans", sans-serif;
  resize: none;
}

.my-text-area::placeholder {
  color: #aaa;
}

.my-text-area:focus {
  outline: none;
  border: 1px solid #909090;
}

.my-input-help {
  font-size: 12px;
  padding-bottom: 10px;
  font-style: italic;
}

.my-input-help-error {
  color: #f64e4e;
}

.my-checkbox {
  padding-bottom: 30px;
}

.my-checkbox div input {
  /* Double-sized Checkboxes */
  transform: scale(1.1);
  padding: 10px;
  margin-top: 5px;
}

.my-checkbox div label {
  font-size: 14px;
  margin-left: 10px;
  line-height: 20px;
  padding-bottom: 5px;
}

.my-checkbox div label a {
  text-decoration: none;
}

.information-from-steps h3 {
  color: #202020;
  padding: 0px 0px 15px 0px;
  line-height: 18px;
  font-weight: 400;
}

.information-from-steps p {
  color: #202020;
  padding: 5px 0px 15px 0px;
  line-height: 18px;
  font-weight: 400;
}

.information-from-steps h3 span {
  font-weight: 600;
}

@media only screen and (min-width: 1050px) {
  .information-from-steps {
    padding: 0px 10px 0px 80px;
  }
}
