@import "./bootstrap.css";
@import "./base.css";
@import "./standard.css";

/* Home */
.p-text-wrap {
  word-wrap: normal;
}

.homepage-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.homepage-icons div h3 {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 20px 0px 7px 0px;
}

.homepage-icons div img {
  padding: 10px 0px 0px 0px;
}

@media only screen and (min-width: 1050px) {
  .homepage-icons {
    flex-direction: row;
    justify-content: space-around;
    width: 800px;
  }
}

.step-h {
  line-height: 100px;
}

.select-ul {
  width: 100%;
  padding: 0px 20px 30px 20px;
}

.select-ul li {
  padding: 10px 0px;
  border-bottom: 1px solid #71717128;
}

.select-ul li:hover {
  cursor: pointer;
}

.select-ul li div p {
  white-space: nowrap;
}

.select-ul li:last-child {
  border-bottom: none;
}

.select-ul h4 {
  font-style: italic;
  padding: 8px 0px;
}

.select-ul h4 span {
  font-style: normal;
  padding-left: 5px;
}

.select-ul h4 span:hover {
  cursor: pointer;
}

.select-ul p {
  padding-right: 10px;
}

@media only screen and (min-width: 1050px) {
  .select-ul {
    width: min(600px, 100%);
  }
}

.display-linebreak {
  white-space: pre-line;
}

.dates-selection {
  width: min(95%, 600px);
  border: 1px solid #71717140;
  border-radius: 10px;
  padding: 10px 0px;
}

.dates-selection h2 {
  line-height: 40px;
  margin-bottom: 20px;
}

.date-div-hr {
  margin: 20px 0px;
  border-top: 1px solid #71717128;
  width: 85%;
}

.dates-selection ul {
  list-style: none;
  padding: 0px 30px;
  height: min(300px, calc(100vh - 516px));
  max-height: 300px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.dates-selection ul li {
  line-height: 40px;
  border-bottom: 1px solid #71717110;
  user-select: none;
}

.dates-selection ul li:last-child {
  border-bottom: none;
}

.dates-selection ul li:hover {
  cursor: pointer;
}

.dot-div-selected {
  border-radius: 5px;
  margin-right: 5px;
  width: 5px;
  height: 5px;
  background-color: #202020;
  margin-right: 10px;
}

.dot-div {
  border-radius: 5px;
  margin-right: 5px;
  width: 5px;
  height: 5px;
  margin-right: 10px;
}

.information-form h3 {
  color: #202020;
  padding: 0px 0px 10px 0px;
}

.information-form {
  padding: 0px 0px 10px 0px;
}

/* Reservation Success page */
.full-page {
  min-height: calc(100vh - 80px);
  padding-bottom: 100px;
}

.full-page h2,
.full-page p {
  line-height: 23px;
  padding: 15px 10px;
}

.review-comment {
  line-height: 20px;
  background-color: #fff;
  border: 1px solid var(--blue-color);
  border-radius: 10px;
  padding: 5px 8px;
  color: var(--blue-color);
  font-weight: 500;
  user-select: none;
}

.review-comment-selected {
  background-color: var(--blue-color);
  color: #fff;
}

.star-not-selected {
  opacity: 0.5;
}

.star-selected {
  opacity: 1 !important;
}

.hover-star {
  opacity: 0.8;
}

.opacity-50 {
  opacity: 0.5;
}

.summary {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.is-recommended {
  padding-left: 10px;
  color: #ff0000c8;
  font-style: italic;
  font-weight: 700;
  line-height: 20px;
}